<template>
  <div class="home-box">
    <div class="home-top" ref="vantaRef">

      <div class="top-log-login-box">
        <div class="left-logo">
          <img class="logo-img" src="../assets/logo1.png" alt="" />
          <span class="logo-text">Fraternity mutual</span>
        </div>
        <div class="right-select-login">
          <img class="language-img" src="../assets/language.png" alt="" />
          <div>
            <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="EN">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="login" @click="diwn()">White paper</div>
        </div>
      </div>
      <div class="top-text1">Fraternity mutual</div>
      <div class="top-text2">
        An open platform for medical mutual assistance developed based on
        blockchain technology
      </div>
      <div class="time-box">
        <Time></Time>
      </div>
    </div>
    <div style="display: flex; wwidth: 820rpx; height: 500rpx; background: #000; margin-top: 30px;">
      <video ref="videoRef" autoplay loop muted style="margin-right: 20px; margin-left: 20px; margin-top: -30px;"  width="820" height="540" controls>
        <source src="../assets/FMpinxuan.mp4"   type="video/mp4"/>
      </video>
    </div>
    <!-- 1 -->
    <div class="carousel-bg-box"  >
      <div class="carousel-box">
        <el-carousel>
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="item-box">
              <div class="info-box">
                <div class="left-box">
                  <div class="top">
                    {{ item.title1 }}
                  </div>
                  <div class="bottom">
                    {{ item.value1 }}
                  </div>
                </div>
                <div class="right-box">
                  <div class="top">
                    {{ item.title2 }}
                  </div>
                  <div class="bottom">
                    {{ item.value2 }}
                  </div>
                </div>
              </div>
              <div class="img-box">
                <img class="item-img" :src="item.url" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="fraternity-bg-box"   >
      <div class="fraternity-box">
        <div class="left-box">
          <div class="text1">Medical mutual fund</div>
          <div class="text2">
            The Fraternity mutual Foundation (hereinafter referred to as the "Foundation") 
            was established and operates in Silicon Valley, United States, and is committed 
            to the development of the Fraternity mutual platform and the transparency of governance,
             and actively promotes and promotes its work. The Foundation will help manage the 
             general and privileged matters of the community by developing a good governance structure. 
             The design criteria of the foundation governance structure mainly consider the 
             sustainability of the platform, the effectiveness of management and the security 
             of the raised funds. The Foundation publishes the progress of its work on development
              matters on a monthly basis, conducts audits every year, and publishes audit reports.
               The foundation consists of a community operation center, a technology development center,
                a marketing center, and a daily management center.
          </div>
        </div>
        <div class="right-box" id="fraternity-right-box">
          <div
            class="item-box"
            v-for="(item, index) in fraternityList"
            :key="index"
          >
            <img :src="item.url" class="item-img" alt="Image" />
            <div class="center-box">
              {{ item.title1 }}
            </div>
            <div class="bottom-box">
              {{ item.value1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="core-purpose-bg-box">
      <div class="core-purpose-box">
        <div class="left-box" id="core-purpose-left-box">
          <div class="text1">
            The core purpose of the Fraternity mutual team
          </div>
          <div class="text2">
           "Redistributing the value of personal health information to improve the quality of health care access and services,
          Build reliable medical mutual assistance services and accelerate the decentralization of personal medical information."
          </div>
          <img class="dh-img" src="@/assets/dh-img.png" alt="" />
          <div class="text3">
            Fraternity mutual will build a healthcare mutual fund with FM-bloc to effectively reduce healthcare 
            costs and enhance the coordination between healthcare and economic growth. Solve the current medical
             situation in India and meet the high-end medical demands of the majority of Indian compatriots
          </div>
          <div class="text4">
           Fraternity mutual will develop a variety of quality health care-related services based on the medical information
           stored on the platform. It will also provide a unique opportunity for all participants on the platform to earn rewards.
          </div>
        </div>
        <div class="right-box">
          <img id="kefu-img" class="kefu-img" src="@/assets/kefu.png" alt="" />
        </div>
      </div>
    </div>
    <div class="ai-robor-bg-box">
      <div class="ai-robor-box">
        <div class="left-box">
          <div class="text1">
            Artificial intelligence medical dialogue robot
          </div>
          <div class="left-cent-box">
            <div class="text2">
              FMAI, based on FM-bloc, can help provide online consultation services,
               provide online consultation services, allow patients and doctors to 
               communicate online, for disease consultation, symptom analysis, 
               treatment plan discussion and other medical activities.
            </div>

            <div class="text3">
              You can communicate with your doctor online through the FMAI 
              platform and describe your symptoms and feelings in detail to 
              your doctor. Doctors can view patients' medical records and
               examination results in time through the FMAI platform to provide 
               patients with more accurate diagnosis and treatment recommendations.
            </div>
          </div>
          <img class="bottom-logo-img" src="@/assets/logo2.png" alt="" />
        </div>
        <div class="right-box">
          <div class="phone-box">
            <!-- <img class="phone-img" src="@/assets/phone.png" alt="" />
            <img
              id="robor-left-img"
              class="left-img"
              src="@/assets/left-right.png"
              alt=""
            />
            <img
              id="robor-right-img"
              class="right-img"
              src="@/assets/right-left.png"
              alt=""
            /> -->
            <video style="margin-right: 10px;"  width="520" height="440" controls>
        <source src="../assets/FMAIDiagnose.mp4"   type="video/mp4"/>
      </video>
          </div>
        </div>
      </div>
    </div>
    <!-- <Phone /> -->
    <div class="home-bottom">
      <img class="bottom-img" src="@/assets/logo2.png" alt="" />
      <div class="text1">Fraternity mutual</div>
      <div class="text2">Fraternity mutual property in copyright  © 2024</div>
    </div>

    <span class="js-cursor-container"></span>
  </div>
</template>

<script>
import Time from "./time.vue";
import Phone from "./phone.vue";
import $ from "jquery";

import * as THREE from 'three'
import Net from 'vanta/src/vanta.birds'


import LwFirewords from 'lw_firewords'

export default {
  components: { 
    Time,
    Phone,
  },
  data() {
    return {
      language: "En",
      carouselList: [
        {
          title1: "Highest level of security",
          value1:
            "Fraternity mutual transfers access to medical information from the healthcare provider to the patient. Only patients can decrypt their own data, freely set access to medical information and record it on the blockchain. It can minimize the leakage of medical information, and at the same time eliminate the possibility of leaking a large number of patients' personal information through a medical institution, and prevent the occurrence of large-scale medical information leakage events.",
          title2: "Extreme reliability",
          value2:
            "Fraternity mutual stores medical information in a distributed data storage space. In order to prevent the loss of data, backup data is continuously generated and maintained, and when the data is forcibly changed or forged, the backup data will be used to restore the original data. The owner of information ownership and management can not change the saved medical information at will, which greatly ensures the integrity and reliability of medical information.",
          url: require("@/assets/03.jpg"),
        },
        {
          title1: "Transparency, notarization and disclosure",
          value1:
            "Decentralized mechanisms can use technology to make charitable healthcare more efficient, transparent and fair. To solve the problem of unbalanced distribution of charity resources, unbalanced distribution of charity fields, non-standard, and low transparency.",
          title2: "Reduce the cost and risk of philanthropy",
          value2:
            "The decentralized mechanism can automatically execute the donation and allocation process through technological means such as smart contracts, avoiding human intervention and error, reducing costs and risks. At the same time, the decentralized mechanism can also support a variety of digital currency donations, increasing public participation.",
          url: require("@/assets/02.jpg"),
        },
        {
          title1: "Promote diverse participation in philanthropy",
          value1:
            "Decentralized mechanisms can connect more people through social networks, friend circles, and other ways to multiply the influence of public welfare. This diversified way of participation can not only improve the efficiency and effect of charity, but also allow more people to participate in charity and jointly promote social progress and development.",
          title2: "Patient-oriented medical assistance system",
          value2:
            "Fraternity mutual uses a distributed and decentralized blockchain technology for data management and integration. Integrate, store and manage not only medical records created by healthcare institutions, but also medical data created by patients themselves. Based on this data, we can reliably authenticate and allocate medical mutual funds, and this integrated medical care information will be widely used in general hospital care and personalized mobile health services.",
          url: require("@/assets/01.jpg"),
        },
      ],
      fraternityList: [
        {
          title1: "Privacy protection",
          value1:
            "The consensus algorithm and encryption algorithm adopted by blockchain technology can ensure the security and privacy of user data. Only certain private key holders can decrypt and access the data, effectively protecting the user's privacy from malicious use or disclosure.",
          url: require("@/assets/num1.png"),
        },
        {
          title1: "Data unification",
          value1:
            "Blockchain technology can establish a shared, distributed, and trusted network of health records, ensuring that data is shared and updated in real time between medical institutions",
          url: require("@/assets/num2.png"),
        },
        {
          title1: "Data security",
          value1:
            "Blockchain technology ensures the security and reliability of data through distributed storage and redundant backup",
          url: require("@/assets/num3.png"),
        },
        {
          title1: "Resource allocation",
          value1:
            "Address the imbalance in the distribution of charity resources, the imbalance in the distribution of fields, the irregular management of charity medical care, the lack of transparency, and the eradication of all kinds of scandals and political corruption.",
          url: require("@/assets/num4.png"),
        },
        {
          title1: "Credit system",
          value1: "Blockchain technology is open and immutable. Improve data security, protect personal privacy, solve the problem of data uniformity, optimize the allocation of medical resources, and build a doctor-patient credit system. Help to improve the efficiency and quality of medical services.",
          url: require("@/assets/num5.png"),
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.vantaEffect = Net({
      el: this.$refs.vantaRef,
      THREE: THREE
    })
    this.$refs.videoRef.addEventListener('ended', this.restartVideo());
 

    const lw_f = new LwFirewords();//创建实例
    lw_f.init();//启动事件

    this.show()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }

   
  },
  computed: {},
  methods: {
     restartVideo() {
            this.$refs.videoRef.currentTime = 0;
            this.$refs.videoRef.play();
            this.$refs.videoRef.muted = false;
        },
    handleCommand(command) {
      this.language = command;
      // this.$message("click on item " + command);
    },
    diwn() {
      window.open("Fraternity mutual.pdf", '_blank');
    },
    handleScroll(e) {
      // const maskEl = document.querySelector(".menu-box .mask");
      // const htmlFontSize = parseInt(
      //   window.getComputedStyle(document.documentElement).fontSize
      // );
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
      const changeHeight = 1 * clientHeight;
      const scrollTop = document.documentElement.scrollTop;

      const fraternityBoxEl = document.getElementById("fraternity-right-box");

      if (clientHeight >= fraternityBoxEl.getBoundingClientRect().top) {
        $(fraternityBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__backInUp animate__speed-1s animate__delay-${
                index + 2
              }00ms`
            );
          });
      }
      const corePurposeLeftBoxEl = document.getElementById(
        "core-purpose-left-box"
      );

      if (clientHeight >= corePurposeLeftBoxEl.getBoundingClientRect().top) {
        $(corePurposeLeftBoxEl)
          .children()
          .each((index, item) => {
            $(item).addClass(
              `animate__animated animate__backInUp animate__speed-1s animate__delay-${
                index + 2
              }00ms`
            );
          });
      }
      const kefuImgEl = document.getElementById("kefu-img");

      if (clientHeight >= kefuImgEl.getBoundingClientRect().top) {
        kefuImgEl.classList.add(
          "animate__animated",
          "animate__fadeInRight",
          "animate__speed-1s",
          "animate__delay-1s"
        );
      }
      const roborLeftImgEl = document.getElementById("robor-left-img");

      if (clientHeight >= roborLeftImgEl.getBoundingClientRect().top) {
        roborLeftImgEl.classList.add(
          "animate__animated",
          "animate__fadeInLeft",
          "animate__speed-1s",
          "animate__delay-1s"
        );
      }
      const roborRightImgEl = document.getElementById("robor-right-img");

      if (clientHeight >= roborRightImgEl.getBoundingClientRect().top) {
        roborRightImgEl.classList.add(
          "animate__animated",
          "animate__fadeInRight",
          "animate__speed-1s",
          "animate__delay-1s"
        );
      }
    },



    show(){
      (function fairyDustCursor() {

        var possibleColors = ["#D61C59", "#E7D84B", "#1B8798"]
        var width = window.innerWidth;
        var height = window.innerHeight;
        var cursor = { x: width / 2, y: width / 2 };
        var particles = [];

        function init() {
          bindEvents();
          loop();
        }

        function bindEvents() {
          document.addEventListener('mousemove', onMouseMove);
          window.addEventListener('resize', onWindowResize);
        }

        function onWindowResize(e) {
          width = window.innerWidth;
          height = window.innerHeight;
        }

        function onMouseMove(e) {
          cursor.x = e.clientX;
          cursor.y = e.clientY;

          addParticle(cursor.x, cursor.y, possibleColors[Math.floor(Math.random() * possibleColors.length)]);
        }

        function addParticle(x, y, color) {
          var particle = new Particle();
          particle.init(x, y, color);
          particles.push(particle);
        }

        function updateParticles() {

          for (var i = 0; i < particles.length; i++) {
            particles[i].update();
          }

          for (var i = particles.length - 1; i >= 0; i--) {
            if (particles[i].lifeSpan < 0) {
              particles[i].die();
              particles.splice(i, 1);
            }
          }

        }

        function loop() {
          requestAnimationFrame(loop);
          updateParticles();
        }

        function Particle() {

          this.character = "*";
          this.lifeSpan = 120; //ms
          this.initialStyles = {
            "position": "fixed",
            "display": "inline-block",
            "top": "0px",
            "left": "0px",
            "pointerEvents": "none",
            "touch-action": "none",
            "z-index": "10000000",
            "fontSize": "25px",
            "will-change": "transform"
          };

          // Init, and set properties
          this.init = function (x, y, color) {

            this.velocity = {
              x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
              y: 1
            };

            this.position = { x: x + 10, y: y + 10 };
            this.initialStyles.color = color;

            this.element = document.createElement('span');
            this.element.innerHTML = this.character;
            applyProperties(this.element, this.initialStyles);
            this.update();

            document.querySelector('.js-cursor-container').appendChild(this.element);
          };

          this.update = function () {
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
            this.lifeSpan--;

            this.element.style.transform = "translate3d(" + this.position.x + "px," + this.position.y + "px, 0) scale(" + (this.lifeSpan / 120) + ")";
          }

          this.die = function () {
            this.element.parentNode.removeChild(this.element);
          }

        }

        function applyProperties(target, properties) {
          for (var key in properties) {
            target.style[key] = properties[key];
          }
        }

        if (!('ontouchstart' in window || navigator.msMaxTouchPoints)) init();
      })();
    },
  },
};
</script>

<style  lang="scss">
$purple: #799df1;
$light-blue: #60c1ec;
$green: #7bd9de;
$pink: #cfb0d2;

$grey: #c7c8c9;

$shine: #f7f7f6;
$black: #000;
.el-dropdown-menu {
  min-width: 80px;
}
.home-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .home-top {
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(36, 54, 29, 1) 0%,
      rgba(19, 23, 17, 1) 100%
    );
    border: 1px solid undefined;
    box-shadow: 0px 40px 180px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .top-log-login-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 90px;
      width: 100%;
      padding: 0 90px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-logo {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo-img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
        .logo-text {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(255, 255, 255, 1);
        }
      }

      .right-select-login {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .language-img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .el-dropdown-link {
          cursor: pointer;
          color: rgba(255, 255, 255, 1);
        }
        .el-icon-arrow-down {
          font-size: 12px;
        }

        .login {
          width: 104px;
          height: 50px;
          border-radius: 10px;
          background: rgb(1, 128, 52);
          color: rgba(255, 255, 255, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .top-text1 {
      height: 84px;
      font-size: 60px;
      font-weight: 900;
      letter-spacing: 31px;
      line-height: 84px;
      background: linear-gradient(
        90deg,
        rgba(251, 255, 245, 1) 0%,
        rgba(238, 255, 201, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
    }
    .top-text2 {
      height: 24px;
      font-size: 16px;
      font-weight: 200;
      letter-spacing: 2px;
      line-height: 24px;
      color: rgba(251, 255, 245, 1);
      margin-bottom: 88px;
    }

    .time-box {
      width: 1052px;
      height: 224px;
    }
  }
  .carousel-bg-box {
    width: 100%;
    height: 985px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('~@/assets/back4.png');

    .carousel-box {
      width: 1440px;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .el-carousel {
        width: 100%;
        height: 100%;
      }

      .el-carousel__container {
        height: 100% !important;
        width: 100%;
      }

      .el-carousel__item {
        width: 100%;
        height: 100%;
        color: #475669;

        .item-box {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .info-box {
            width: 100%;
            height: 200px;
            display: flex;

            .left-box,
            .right-box {
              width: 50%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 30px;
              box-sizing: border-box;

              .top {
                font-size: 24px;
                font-weight: 900;
                line-height: 33px;
                color: rgba(2, 129, 52, 1);
                text-align: left;
                margin-bottom: 10px;
              }
              .bottom {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 24px;
                color: rgba(67, 77, 70, 1);
                text-align: left;
              }
            }
          }

          .img-box {
            height: 0;
            flex: 1;
            width: 100%;

            .item-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .fraternity-bg-box {
    width: 100%;
    height: 985px;
    background: rgba(247, 251, 249, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('~@/assets/back4.png');


    .fraternity-box {
      width: 1440px;
      height: 100%;
      // background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      box-sizing: border-box;

      .left-box {
        width: 460px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .text1 {
          font-size: 48px;
          font-weight: 900;
          letter-spacing: 0px;
          line-height: 56px;
          color: rgba(40, 41, 56, 1);
          text-align: left;
          margin-bottom: 96px;
        }
        .text2 {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 24px;
          color: rgba(131, 138, 133, 1);
          text-align: left;
        }
      }
      .right-box {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 150px;
        box-sizing: border-box;

        .item-box {
          width: 50%;
          padding-right: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .item-img {
            width: 47px;
            height: 50px;
            margin-bottom: 16px;
          }
          .center-box {
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 0px;
            line-height: 34px;
            color: rgba(2, 18, 8, 1);
            text-align: left;
            margin-bottom: 22px;
          }
          .bottom-box {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 24px;
            color: rgba(67, 77, 70, 1);
            text-align: left;
          }
        }
      }
    }
  }

  .core-purpose-bg-box {
    width: 100%;
    height: 985px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('~@/assets/back4.png');
    .core-purpose-box {
      width: 1440px;
      height: 100%;
      // background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;

      .left-box {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .text1 {
          width: 560px;
          font-size: 48px;
          font-weight: 900;
          letter-spacing: 0px;
          line-height: 56px;
          color: rgba(2, 18, 8, 1);
          text-align: left;
          margin-bottom: 30px;
        }
        .text2 {
          width: 421px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 24px;
          color: rgba(131, 138, 133, 1);
          text-align: left;
          margin-bottom: 60px;
        }

        .dh-img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          margin-bottom: 14px;
          margin-left: 20px;

          &:hover {
            box-shadow: inset 0 0 1rem 0 #028134, 0 0 1rem 0 #028134;
          }
        }
        .text3 {
          width: 411px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 24px;
          color: rgba(67, 77, 70, 1);
          text-align: left;
          margin-left: 20px;
          margin-bottom: 20px;
        }
        .text4 {
          width: 411px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 24px;
          color: rgba(67, 77, 70, 1);
          text-align: left;
          margin-left: 20px;
        }
      }
      .right-box {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .kefu-img {
          width: 486px;
          height: 540px;
          border-radius: 10px;
        }
      }
    }
  }
  .ai-robor-bg-box {
    width: 100%;
    height: 985px;
    background: rgba(247, 251, 249, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('~@/assets/back4.png');

    .ai-robor-box {
      width: 1440px;
      height: 100%;
      // background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;

      .left-box {
        width: 776px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .text1 {
          font-size: 48px;
          font-weight: 900;
          letter-spacing: 0px;
          line-height: 56px;
          color: rgba(2, 18, 8, 1);
          text-align: left;
          margin-bottom: 30px;
        }
        .left-cent-box {
          width: 544px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          .text2 {
            width: 250px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 24px;
            color: rgba(67, 77, 70, 1);
            text-align: left;
            margin-right: 40px;
          }
          .text3 {
            width: 250px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 24px;
            color: rgba(67, 77, 70, 1);
            text-align: left;
          }
        }

        .bottom-logo-img {
          width: 78px;
          height: 80px;
        }
      }
      .right-box {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .phone-box {
          width: 486px;
          height: 540px;
          position: relative;

          .phone-img {
            width: 486px;
            height: 540px;
            border-radius: 10px;
            position: relative;
            z-index: 5;
          }
          .left-img {
            position: absolute;
            z-index: 7;
            width: 349px;
            height: 180px;
            left: -200px;
            top: 300px;
          }
          .right-img {
            position: absolute;
            z-index: 2;
            top: 58px;
            right: -150px;
            width: 173px;
            height: 111px;
          }
        }
      }
    }
  }
  .home-bottom {
    
    width: 100%;
    height: 270px;
    background: linear-gradient(
      90deg,
      rgba(233, 255, 186, 1) 0.69%,
      rgba(192, 255, 189, 1) 100%
    );
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 56px 233px 56px 233px;
    box-sizing: border-box;

    .bottom-img {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }
    .text1 {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 28.13px;
      color: rgba(2, 18, 8, 1);
      margin-bottom: 40px;
    }
    .text2 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(2, 18, 8, 1);
    }
  }
}
</style>
