<template>
  <div class="ai-robor-box">
    <div class="left-box">
      <div class="text1">Artificial intelligence medical dialogue robot</div>
      <div class="left-cent-box">
        <div class="text2">
          At the same time, the FMAI developed by FM-bloc can help provide
          online consultation services, provide online consultation services,
          allow patients and doctors to communicate online, carry out disease
          consultation, symptom analysis, treatment plan discussion and other
          medical activities.
        </div>

        <div class="text3">
          At the same time, the FMAI developed by FM-bloc can help provide
          online consultation services, provide online consultation services,
          allow patients and doctors to communicate online, carry out disease
          consultation, symptom analysis, treatment plan discussion and other
          medical activities.
        </div>
      </div>
      <img class="bottom-logo-img" src="@/assets/logo2.png" alt="" />
    </div>
    <div class="right-box">
      <div class="phone-box">
        <img class="phone-img" src="@/assets/phone.png" alt="" />

        <img class="left-img" src="@/assets/left-right.png" alt="" />
        <img
          class="right-img"
          :class="[
            'animate__animated',
            'animate__fadeInUp',
            'animate__delay-2s',
          ]"
          src="@/assets/right-left.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>  
    
  <script>
export default {
  data() {
    return {
      endTime: null, 
      timer: null, 
      currentTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      isFinished: false,
    };
  },
  computed: {
    formattedDays() {
      return this.padZero(this.currentTime.days);
    },
    formattedHours() {
      return this.padZero(this.currentTime.hours);
    },
    formattedMinutes() {
      return this.padZero(this.currentTime.minutes);
    },
    formattedSeconds() {
      return this.padZero(this.currentTime.seconds);
    },
  },
  mounted() {
    // this.startCountdown("2024-4-11 23:32:00");
    this.startCountdown("2024-4-10 23:32:00");
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    padZero(num) {
      return num < 10 ? "0" + num : num;
    },
    startCountdown(endTime) {
      this.endTime = new Date(endTime).getTime();
      this.updateCountdown();
    },
    updateCountdown() {
      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const remainingSeconds = Math.max(
          Math.ceil((this.endTime - now) / 1000),
          0
        );

        if (remainingSeconds === 0) {
          this.stopCountdown();
          this.isFinished = true;
        } else {
          this.currentTime.days = Math.floor(remainingSeconds / (60 * 60 * 24));
          this.currentTime.hours = Math.floor(
            (remainingSeconds % (60 * 60 * 24)) / (60 * 60)
          );
          this.currentTime.minutes = Math.floor(
            (remainingSeconds % (60 * 60)) / 60
          );
          this.currentTime.seconds = Math.floor(remainingSeconds % 60);
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handleButtonClick() {
      alert("111");
    },
  },
};
</script>
  <style lang="scss">
.ai-robor-box {
  width: 1440px;
  height: 100vh;
  // background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  .left-box {
    width: 776px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .text1 {
      font-size: 48px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 56px;
      color: rgba(2, 18, 8, 1);
      text-align: left;
      margin-bottom: 30px;
    }
    .left-cent-box {
      width: 544px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .text2 {
        width: 250px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(67, 77, 70, 1);
        text-align: left;
        margin-right: 40px;
      }
      .text3 {
        width: 250px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(67, 77, 70, 1);
        text-align: left;
      }
    }

    .bottom-logo-img {
      width: 78px;
      height: 80px;
    }
  }
  .right-box {
    width: 0;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .phone-box {
      width: 486px;
      height: 540px;
      position: relative;

      .phone-img {
        width: 486px;
        height: 540px;
        border-radius: 10px;
        position: relative;
        z-index: 5;
      }
      .left-img {
        position: absolute;
        z-index: 7;
        width: 349px;
        height: 180px;
        left: -200px;
        top: 300px;
      }
      .right-img {
        position: absolute;
        z-index: 2;
        top: 58px;
        right: -150px;
        width: 173px;
        height: 111px;
      }
    }
  }
}
</style>