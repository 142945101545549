<template>
  <div class="countdown-box">
    <!-- <div class="time-box" v-if="!isFinished">
      <div class="itme-text">Countdown to online</div>
      <div class="all-time-box">
        <div class="time-item">
          <div class="value">{{ formattedDays }}</div>
          <div class="text">DAYS</div>
        </div>
        <div class="time-item">
          <div class="value">{{ formattedHours }}</div>
          <div class="text">HOURS</div>
        </div>
        <div class="time-item">
          <div class="value">{{ formattedMinutes }}</div>
          <div class="text">MINUTES</div>
        </div>
        <div class="time-item">
          <div class="value">{{ formattedSeconds }}</div>
          <div class="text">SECONDS</div>
        </div>
      </div>
    </div> -->

    <div class="btn-box">
      <a href="https://t.me/fmutual8899" class="btn">JOIN Community</a>
      <a href="https://t.me/Fraternitymutual" class="btn">JOIN Channel</a>
    </div>
   
  </div>
</template>  
    
  <script>
export default {
  data() {
    return {
      endTime: null,
      timer: null, 
      currentTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      isFinished: false, 
    };
  },
  computed: {
    formattedDays() {
      return this.padZero(this.currentTime.days);
    },
    formattedHours() {
      return this.padZero(this.currentTime.hours);
    },
    formattedMinutes() {
      return this.padZero(this.currentTime.minutes);
    },
    formattedSeconds() {
      return this.padZero(this.currentTime.seconds);
    },
  },
  mounted() {
    // this.startCountdown("2024-4-4 23:32:00");
    this.startCountdown("2024-03-30 12:00:00");
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    padZero(num) {
      return num < 10 ? "0" + num : num;
    },
    startCountdown(endTime) {
      this.endTime = new Date(endTime).getTime();
      this.updateCountdown();
    },
    updateCountdown() {
      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const remainingSeconds = Math.max(
          Math.ceil((this.endTime - now) / 1000),
          0
        );

        if (remainingSeconds === 0) {
          this.stopCountdown();
          this.isFinished = true; 
        } else {
          this.currentTime.days = Math.floor(remainingSeconds / (60 * 60 * 24));
          this.currentTime.hours = Math.floor(
            (remainingSeconds % (60 * 60 * 24)) / (60 * 60)
          );
          this.currentTime.minutes = Math.floor(
            (remainingSeconds % (60 * 60)) / 60
          );
          this.currentTime.seconds = Math.floor(remainingSeconds % 60);
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handleButtonClick() {
      navigator.clipboard.writeText("https://app.fmutual.org")
      .then(() => {
        this.$message.success('Copy successfully, please open it in your wallet')
      })
    },
  },
};
</script>
  <style lang="scss">
$purple: #799df1;
$light-blue: #60c1ec;
$green: #7bd9de;
$pink: #cfb0d2;

$grey: #c7c8c9;

$shine: #f7f7f6;
$black: #000;

.countdown-box {
  width: 100%;
  height: 100%;

  .time-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .itme-text {
      height: 24px;
      font-size: 16px;
      font-weight: 200;
      letter-spacing: 2px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.4);
    }

    .all-time-box {
      width: 100%;
      height: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time-item {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .value {
          font-size: 140px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 1);
        }

        .text {
          font-size: 24px;
          font-weight: 200;
          letter-spacing: 2px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .btn-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // .btn {
    //   width: 391px;
    //   height: 129px;
    //   border-radius: 29px;
    //   background: linear-gradient(
    //     125.57deg,
    //     rgb(189, 255, 41) 0%,
    //     rgb(43, 255, 230) 100%
    //   );
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   padding: 31px 54px 31px 54px;

    //   font-size: 72px;
    //   font-weight: 700;
    //   letter-spacing: 0px;
    //   color: rgba(2, 18, 8, 1);

    //   margin-bottom: 20px;
    // }

    .btn {
      width: 300px;
    height: 129px;
    line-height: 129px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    z-index: 0;
    padding: 1rem 3rem;
    border: 1px solid #799df1;
    border-radius: 9999px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    color: #021208;
    text-transform: uppercase;
    color: #000;
    box-shadow: inset 0 0 1rem 0 #f7f7f6;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow .5s ease;
    background: linear-gradient(60deg, #799df1 5%, #7bd9de 25%, #799df1 45%, #7bd9de 65%, #799df1 85%, #7bd9de);
    background-size: 200% 200%;
    background-position: -100% -100%;
    animation: moving1;
    animation-duration: 8s;
    animation-iteration-count: infinite;
      //   background: linear-gradient(
      //     60deg,
      //     $purple 5%,
      //     $light-blue 15%,
      //     $green 25%,
      //     $pink 35%,
      //     $purple 45%,
      //     $light-blue 55%,
      //     $green 65%,
      //     $pink 75%,
      //     $purple 85%,
      //     $light-blue 95%,
      //     $green
      //   );
      background: linear-gradient(
        60deg,
        $purple 5%,
        $green 25%,
        $purple 45%,
        $green 65%,
        $purple 85%,
        $green
      );
      background-size: 200% 200%;
      background-position: -100% -100%;

      @keyframes moving1 {
        20% {
          background-position: -130% -120%;
        }

        40% {
          background-position: -140% -100%;
        }

        70% {
          background-position: -110% -130%;
        }
      }

      animation: moving1;
      animation-duration: 8s;
      animation-iteration-count: infinite;

      &:before {
        content: "";
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        width: calc(100% - 0.4rem);
        height: calc(100% - 0.4rem);
        border-radius: 9999px;
        border-top: 1px solid rgba($shine, 0.5);
        border-right: 1px solid rgba($shine, 0.5);
        box-shadow: 0 0 1rem 1rem rgba($grey, 0.4);
        background: linear-gradient(180deg, transparent, $grey 160%);
        overflow: hidden;
      }

      &:after {
        content: "";
        width: 200%;
        height: 200%;
        position: absolute;
        top: -50%;
        left: -50%;
        background: linear-gradient(
          150deg,
          $shine,
          transparent 20%,
          transparent 60%,
          $shine 70%,
          $shine
        );

        @keyframes moving2 {
          20% {
            transform: skewY(-10deg) translateX(20%);
          }

          40% {
            transform: translateX(-10%) rotate(20deg);
          }

          70% {
            transform: translateX(20%) skewX(20deg) translateY(-10%);
          }
        }

        animation: moving2;
        animation-duration: 20s;
        animation-iteration-count: infinite;
      }

      &:hover {
        // box-shadow: inset 0 0 1rem 0 $shine, 0 0 1rem 0 $shine;
        box-shadow: inset 0 0 1rem 0 #028134, 0 0 1rem 0 #028134;
      }

      @media screen and (max-width: 450px) {
        font-size: 1rem;
      }
    }

    a {
      color: rgba(251, 255, 245, 1); 
      font-size: 30px;
    }

  }
}
</style>